<!-- 供货商品 -->

<template>
  <div class="supply">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-cascader :options="typeOptions" placeholder="筛选分类" v-model="categoryIds" @change="onSearch"
          :props="cascaderprops" collapse-tags clearable></el-cascader>
        <el-input v-model="barCode" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="productValue" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="brandValue" placeholder="请搜索品牌" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <!-- <el-input v-model="categoryValue" placeholder="请搜索分类" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input> -->
        <el-select v-model="selectValue" placeholder="上下架状态" @change="handleSelect" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-button class="btn" type="primary" plain @click="handleExport">导出</el-button>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" :cell-style="reviewRowClass">
          <el-table-column label="序号" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="图片" width="110">
            <template slot-scope="scope">
              <span class="show_img" @click="previewImage(scope.row.picUrlList)">预览图片</span>
            </template>
          </el-table-column> -->
          <el-table-column label="图片" width="100" align="center">
            <template slot-scope="scope">
              <el-image class="busilicense"
                :src="scope.row.picUrlList && scope.row.picUrlList.length > 0 ? scope.row.picUrlList[0] : ''"
                fit="cover" @mouseover="previewImage(scope.row.picUrlList)"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="brand" label="品牌" show-overflow-tooltip min-width="120"></el-table-column>
          <!-- <el-table-column prop="serialId" label="商品编号" show-overflow-tooltip min-width="120"></el-table-column>-->
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="150"></el-table-column>
          <el-table-column prop="factoryName" label="厂家" show-overflow-tooltip min-width="120"></el-table-column>
          <el-table-column prop="specs" label="规格" show-overflow-tooltip min-width="120"></el-table-column>
          <el-table-column prop="categoryName" label="分类" show-overflow-tooltip min-width="100"></el-table-column>
          <el-table-column prop="enterBox" label="入箱数" show-overflow-tooltip min-width="100"></el-table-column>
          <el-table-column prop="tenderProductPrice" label="中标供货价" show-overflow-tooltip
            min-width="100"></el-table-column>
          <el-table-column prop="depositAmount" label="履约保证金" show-overflow-tooltip min-width="100"></el-table-column>
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="160"></el-table-column>
          <el-table-column label="缴费状态" show-overflow-tooltip min-width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.depositStatus == 0">未缴费</span>
              <span v-if="scope.row.depositStatus == 1">已缴费</span>
              <span v-if="scope.row.depositStatus == 2">逾期未缴费</span>
            </template>
          </el-table-column>
          <el-table-column prop="saleStatus" label="上下架状态" show-overflow-tooltip min-width="100"></el-table-column>
          <el-table-column label="中标有效期" header-align="center" show-overflow-tooltip min-width="210">
            <template slot-scope="scope">{{ scope.row.startDate }} 至 {{ scope.row.endDate }}</template>
          </el-table-column>
          <el-table-column prop="expirationDay" label="保质期(天)" show-overflow-tooltip min-width="100"></el-table-column>
          <!-- <el-table-column show-overflow-tooltip prop="firstWarnDay" label="第一次预警" min-width="90">
            <template slot-scope="scope">
              <div v-if="scope.row.firstWarnDay && scope.row.firstWarnPercent">
                {{ `${scope.row.firstWarnDay}天(${scope.row.firstWarnPercent}%)` }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="secondWarnDay" label="第二次预警(天)" show-overflow-tooltip min-width="115"></el-table-column> -->
          <el-table-column label="操作" header-align="center" align="center" min-width="200" fixed="right">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain
                v-if="scope.row.saleStatus == '下架' && scope.row.depositStatus == 1 && scope.row.tendersDateStatus == 1"
                @click="handleSaleStatus(scope.row)">上架</el-button>
              <el-button size="small" class="btn" type="" plain
                v-if="scope.row.saleStatus == '上架' && scope.row.depositStatus == 1 && scope.row.tendersDateStatus == 1"
                @click="handleSaleStatus(scope.row)">下架</el-button>
              <!-- <el-button size="small" class="btn" type="primary" plain
                v-if="scope.row.depositStatus == '0' && scope.row.saleStatus == '上架'">缴保证金</el-button> -->
              <!-- <el-button class="btn" type="primary" plain @click="handleOpenPicDialog(scope.row)"
                v-if="scope.row.depositStatus == 1">编辑</el-button> -->
              <el-button class="btn" type="primary" plain @click="goToEdit(scope.row)"
                v-if="scope.row.depositStatus == 1 && scope.row.tendersDateStatus == 1 && scope.row.status == 2">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <!-- 编辑图片 -->
    <el-dialog title="编辑" :visible.sync="picDialogVisible" :before-close="onCancelPicDialog">
      <el-form :model="picRuleForm" :rules="picRules" ref="picRuleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" prop="productName">
              <el-input v-model="picRuleForm.productName" placeholder="自动带入" readonly></el-input>
            </el-form-item>

            <el-form-item class="item logo_pic" label="商品图片" prop="totalPic">
              <div class="pic-row">
                <div class="pic">
                  <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action"
                    :show-file-list="false" :on-success="handleSuccess1" :before-upload="beforeUploads"
                    :on-remove="handleRemoveLogo1" :disabled="picRuleForm.picUrl1 ? true : false">
                    <img v-if="picRuleForm.picUrl1" :src="picRuleForm.picUrl1" class="logoimg" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <i v-if="picRuleForm.picUrl1" class="delpic delpic1 el-icon-error" @click="handleRemoveLogo1"></i>
                </div>
                <div class="pic">
                  <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action"
                    :show-file-list="false" :on-success="handleSuccess2" :before-upload="beforeUploads"
                    :on-remove="handleRemoveLogo2" :disabled="picRuleForm.picUrl2 ? true : false">
                    <img v-if="picRuleForm.picUrl2" :src="picRuleForm.picUrl2" class="logoimg" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <i v-if="picRuleForm.picUrl2" class="delpic delpic2 el-icon-error" @click="handleRemoveLogo2"></i>
                </div>
                <div class="pic">
                  <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action"
                    :show-file-list="false" :on-success="handleSuccess3" :before-upload="beforeUploads"
                    :on-remove="handleRemoveLogo3" :disabled="picRuleForm.picUrl3 ? true : false">
                    <img v-if="picRuleForm.picUrl3" :src="picRuleForm.picUrl3" class="logoimg" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <i v-if="picRuleForm.picUrl3" class="delpic delpic3 el-icon-error" @click="handleRemoveLogo3"></i>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancelPicDialog">取 消</el-button>
        <el-button type="primary" @click="submitFormPic('picRuleForm')">确 定</el-button>
      </span>
    </el-dialog>

    <el-image-viewer v-if="showViewer" :on-close="() => {
      showViewer = false
    }
      " :url-list="imgList"></el-image-viewer>
  </div>
</template>

<script>
export default {
  components: {
    "el-image-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      loading: false,
      // 下拉框数据
      selectValue: "",
      options: [{
        value: "1",
        label: "上架",
      },
      {
        value: "0",
        label: "下架",
      },
      ],
      productValue: "", // 搜索商品名称
      brandValue: "", // 请搜索品牌
      barCode: "",
      categoryValue: "", // 请搜索分类
      categoryIds: [], // 请搜索分类
      typeOptions: [],
      cascaderprops: {
        checkStrictly: false,
        emitPath: false,
        multiple: true,
        value: "categoryId",
        label: "categoryName",
        children: "children",
      },
      // 表格数据
      tableData: [],
      // 图片预览
      showViewer: false,
      imgList: [],
      // 编辑图片
      picDialogVisible: false,
      picRuleForm: {
        productName: "",
        productId: "",
        totalPic: "",
        picUrl1: "",
        picUrl2: "",
        picUrl3: "",
      },
      picRules: {
        totalPic: [{
          validator: () => {
            if (!this.picRuleForm.picUrl1 && !this.picRuleForm.picUrl2 && !this.picRuleForm.picUrl3) {
              return Promise.reject("请至少上传一张图片")
            } else {
              return Promise.resolve()
            }
          },
          trigger: "submit",
        },],
      },
      action: this.$axios.defaults.baseURL + this.$api.uploadFile,
      // 分页
      currentPage: 2, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    let cp = localStorage.getItem("currentPage")
    this.currentPage = cp ? Number(cp) : 1
    localStorage.removeItem("currentPage")

    this.getSuppliedGoodsList()
    this.getCategoryInfoList()
  },
  methods: {
    // 获取商品列表
    getSuppliedGoodsList() {
      this.loading = true
      this.$axios
        .post(this.$api.getSuppliedGoodsList2, {
          categoryIdLists: this.categoryIds.length == 0 ? null : this.categoryIds, // 请搜索分类
          productName: this.productValue || null, // 搜索商品名称
          brand: this.brandValue || null, // 请搜索品牌
          barCode: this.barCode || null,
          // categoryName: this.categoryValue || null, // 请搜索分类
          page: this.currentPage,
          pageSize: this.pageSize,
          auditStatus: "1",
          saleStatus: this.selectValue || null, // 销售状态（上下架状态）
          selectType: 1, // 查询类型
        })
        .then(res => {
          if (res.data.code == 100) {
            let list = res.data.result.list.map(item => {
              let saleStatus = item.saleStatus == 0 ? "下架" : item.saleStatus == 1 ? "上架" : ""
              // if (item.tendersStatus === 1) {
              //   saleStatus = item.saleStatus == 0 ? "下架" : item.saleStatus == 1 ? "上架" : ""
              // } else if (item.tendersStatus == 2) {
              //   saleStatus = "-"
              // }
              return {
                ...item,
                saleStatus: saleStatus,
              }
            })
            this.tableData = list
            this.totalItemsCount = res.data.result.totalCount
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 获取商品列表
    getCategoryInfoList() {
      this.loading = true
      this.$axios
        .get(this.$api.getCategoryInfoList, {
          params: {
            auditStatus: "1",
            page: 1,
            pageSize: 9999,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.typeOptions = res.data.result.list
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleExport() {
      this.$axios
        .post(this.$api.exportExcel, {
          categoryIdLists: this.categoryIds.length == 0 ? null : this.categoryIds, // 搜索分类
          productName: this.productValue || null, // 搜索商品名称
          brand: this.brandValue || null, // 搜索品牌
          page: this.currentPage,
          pageSize: this.pageSize,
          auditStatus: "1",
          saleStatus: this.selectValue || null, // 销售状态（上下架状态）
          selectType: 1, // 查询类型
        }, {
          responseType: 'blob' // 设置响应类型为blob
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/octet-stream'
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = '供货商品.xlsx';
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          this.$message.error("下载出错")
        });
    },

    // 编辑
    goToEdit(row) {
      localStorage.setItem("currentPage", this.currentPage)
      this.$router.push({
        path: "/products/detail/edit",
        query: {
          productId: row.productId
        }
      })
    },
    handleOpenPicDialog(row) {
      this.picDialogVisible = true
      this.$set(this.picRuleForm, "productName", row.productName)
      this.$set(this.picRuleForm, "productId", row.productId)
      if (row.picUrlList && Array.isArray(row.picUrlList)) {
        this.$set(this.picRuleForm, "picUrl1", row.picUrlList[0] || "")
        this.$set(this.picRuleForm, "picUrl2", row.picUrlList[1] || "")
        this.$set(this.picRuleForm, "picUrl3", row.picUrlList[2] || "")
      } else {
        this.$set(this.picRuleForm, "picUrl1", "")
        this.$set(this.picRuleForm, "picUrl2", "")
        this.$set(this.picRuleForm, "picUrl3", "")
      }
    },

    // 关闭对话框
    onCancelPicDialog() {
      this.picDialogVisible = false
      this.resetFormPic()
    },
    // 提交表单
    submitFormPic(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios
            .post(this.$api.updateProductPic, {
              productId: this.picRuleForm.productId,
              picUrlList: [this.picRuleForm.picUrl1 || "", this.picRuleForm.picUrl2 || "", this.picRuleForm.picUrl3 || ""],
            })
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success(res.data.desc)
                this.getSuppliedGoodsList()
                this.onCancelPicDialog()
              }
            })
            .catch(err => {
              this.$message.error(err.data.desc)
            })
        }
      })
    },
    // 重置校验
    resetFormPic() {
      this.$refs.picRuleForm.resetFields()
      this.picRuleForm = {}
    },
    handleRemoveLogo1() {
      this.picRuleForm.picUrl1 = ""
    },
    handleSuccess1(res) {
      if (res.code == 100) {
        this.picRuleForm.picUrl1 = res.result.fileUrl
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        })
      }
    },
    handleRemoveLogo2() {
      this.picRuleForm.picUrl2 = ""
    },
    handleSuccess2(res) {
      if (res.code == 100) {
        this.picRuleForm.picUrl2 = res.result.fileUrl
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        })
      }
    },
    handleRemoveLogo3() {
      this.picRuleForm.picUrl3 = ""
    },
    handleSuccess3(res) {
      if (res.code == 100) {
        this.picRuleForm.picUrl3 = res.result.fileUrl
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        })
      }
    },
    beforeUploads(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png"
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error("图文只支持jpg、png、jpeg，请调整格式再上传!")
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!")
      }
      return isJPG && isLt5M
    },
    // 清空
    clearData() {
      this.tableData = []
      this.currentPage = 1
    },
    // 下拉选择
    handleSelect(value) {
      this.clearData()
      this.selectValue = value
      this.getSuppliedGoodsList()
    },
    // 搜索
    onSearch() {
      this.clearData()
      this.getSuppliedGoodsList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData()
      this.pageSize = e
      this.getSuppliedGoodsList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getSuppliedGoodsList()
    },
    // 上下架状态颜色变化
    reviewRowClass({
      row,
      columnIndex
    }) {
      if (columnIndex == 12 && row.saleStatus == "下架") {
        return {
          color: "#EB2500",
        }
      }
      if (columnIndex == 11 && row.depositStatus == "0") {
        return {
          color: "#EB2500",
        }
      }
    },
    // 上架 / 下架
    handleSaleStatus(row) {
      // 校验图片列表长度
      //if (row.saleStatus == '下架' && (!row.picUrlList || row.picUrlList.length === 0)) {
      // this.$message({
      //  type: 'warning',
      //  message: '请先上传商品图片再进行上架操作'
      //});
      //  return; // 阻止上架操作
      //}

      const confirmMessage = row.saleStatus == "上架" ? "确定下架商品吗？" : "确定上架商品吗？"
      const successMessage = row.saleStatus == "上架" ? "下架成功!" : "上架成功!"

      this.$confirm(confirmMessage, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .put(this.$api.changeProductInfoSupplierAudit, {
              thirdStatus: row.thirdStatus,
              id: row.id,
              saleStatus: row.saleStatus == "下架" ? 1 : 0,
            })
            .then(res => {
              if (res.data.code == 100) {
                this.getSuppliedGoodsList()
                this.$message({
                  type: "success",
                  message: successMessage,
                })
              }
            })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${row.saleStatus == "上架" ? "下架" : "上架"}`,
          })
        })
    },
    // 图片预览
    previewImage(url) {
      this.imgList = url
      this.showViewer = true
    },
  },
}
</script>

<style lang="scss" scoped>
.supply {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    .el-input {
      width: 200px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .el-select,
    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }

    ::v-deep .el-cascader {
      width: 280px;
      margin-bottom: 20px;

      .el-input,
      .el-input__inner {
        width: 280px;
      }
    }

    ::v-deep .el-cascader__tags .el-tag {
      background-color: #fff;
    }

    ::v-deep .el-tag span {
      max-width: 100px;
    }
  }

  // 图片
  .logo_pic {
    position: relative;

    ::v-deep .el-form-item__content {
      width: 100px;
      height: 100px;
    }

    .pic-row {
      display: flex;

      .pic {
        margin-right: 15px;

        .logo_uploader {
          ::v-deep .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
          }

          ::v-deep .el-upload:hover {
            border-color: #069bbc;
          }

          .avatar-uploader-icon {
            font-size: 28px;
            color: #069bbc;
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
          }

          .logoimg {
            width: 100px;
            height: 100px;
            display: block;
            object-fit: cover;
          }
        }
      }
    }

    .delpic {
      position: absolute;
      top: -12px;
      color: red;
      font-size: 20px;
      cursor: pointer;
    }

    .delpic1 {
      right: -15px;
    }

    .delpic2 {
      right: -134px;
    }

    .delpic3 {
      right: -250px;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .busilicense {
    display: block;
    width: 80px;
    height: 58px;
    border-radius: 4px;
  }
}
</style>
